<template>
  <div class="p-l-md p-r-md">
    <ListFrame
      :immediateInit="false"
      :viewConf="{showReorder:false,showTitle:true,showShelStatus:true,showReset:true,shelfOperateAble:is6Verify?{offshelfFn,onshelfFn}:false}"
      :customedParams="{contentType:laun+'Teaching'}"
      :getPageFn="getPage"
    >
      <template slot-scope="{item}">
        <TeachingItem :info="item" />
        <Button type="warning" @click="preview(item)" style="margin-right:1.5em;">查看</Button>
        <template v-if="[4,5].includes($store.getters.getRoleCode)">
          <RewriteEntry
            style="display:inline-block;"
            :isOnshelf="item.status == 3"
            :rewrited="!item.rewrite"
            :editorname="item.rewriteName"
            @rewrite="toRewritePage(item)"
            @info="toVerify6(item)"
          />
        </template>
        <span class="status-span active" v-if="item.status==3">已上架</span>
        <span class="status-span" v-else>
          已下架
          <span style="padding-left:1em">下架原因：{{item.remark}}</span>
        </span>
      </template>
      <template v-slot:conditions="{}"></template>
    </ListFrame>
  </div>
</template>

<script>
import { getList } from "./services/teachingService";
import { getSubmitedInfo, offShelf, onShelf } from "./services/api";

export default {
  data() {
    return {};
  },
  computed: {
    is6Verify() {
      return this.$store.getters.getRoleCode === 6;
    },
    laun() {
      return this.$store.getters.getLaun;
    }
  },
  methods: {
    getPage(params) {
      return getList("published", params);
    },
    preview(item) {
      getSubmitedInfo({
        bizId: item.id,
        taskName: this.$store.getters.getRoleCode,
        contentType: item.contentType
      }).then(res => {
        this.$mobilePreview(res.content);
      });
    },
    offshelfFn(list, reason) {
      let arr = list.map(({ id, processId, remark, contentType }) => ({
        id,
        processId,
        remark,
        contentType,
        taskName: this.$store.getters.getRoleCode
      }));
      return offShelf(arr, reason);
    },
    onshelfFn(list) {
      let arr = list.map(({ id, processId, remark, contentType }) => ({
        id,
        processId,
        remark,
        contentType,
        taskName: this.$store.getters.getRoleCode
      }));
      return onShelf(arr);
    },
    getRewritePageRoute(info) {

      return this.$router.resolve({
        path: {5:"/edit4audio",4:"/imgPage"}[this.$store.getters.getRoleCode],
        query: {
          rewriteId: info.id,
          contentType: info.contentType,
          parentProcessId: info.processId
        }
      });
    },
    toRewritePage(info) {
      let routeObj = this.getRewritePageRoute(info);
      this.$router.push(routeObj.route);
    },
    toVerify6(info) {
      let routeObj = this.getRewritePageRoute(info);
      let { path, query } = routeObj.route;
      let desRoute = JSON.stringify({ path, query });
      this.$router.push({
        path: "/verify6",
        query: {
          rewriteId: info.id,
          contentType: info.contentType,
          parentProcessId: info.processId,
          desRoute4rewrite: desRoute
        }
      });
    }
  }
};
</script>

<style scoped>
/deep/ .cond-shelf {
  flex: 1;
  text-align: right;
}
/deep/ .cond-shelf > .ivu-select {
  text-align: left;
}
</style>